export const getBase = store => store.base;
export const getTickets = store => store.tickets;
export const getGiftCardData = store => store.giftCard;
export const getBaseLang = store => getBase(store).lang;
export const getBanners = store => getBase(store).banners;
export const getConcessions = store => store.concessions;
export const getSessionStartTime = store => getBase(store).sessionStartTime;
export const getKinoParkGiftCards = store => getGiftCardData(store).giftCards;
export const getConcessionsActiveFilter = store => getConcessions(store).activeFilter;
export const getOrderedConcessionData = store => getConcessions(store).data;
export const getSessionStartTimeIsLoaded = store => getBase(store).sessionStartTimeIsLoaded;

export const getConcessionItems = store => {
  const lang = getBaseLang(store);
  const concession = getConcessions(store).concessions || [];

  return concession.reduce(
    (acc, { GroupName, ConcessionItems }) => ({ ...acc, [GroupName[lang]]: ConcessionItems }),
    {},
  );
};
