import { lazy } from 'react';

import { withSuspense } from 'utils';

const router = [
  {
    path: '/',
    Component: withSuspense(lazy(() => import('pages/main'))),
  },
  {
    path: '/giftCard',
    Component: withSuspense(lazy(() => import('pages/giftCard'))),
  },
  {
    path: '/concessions',
    Component: withSuspense(lazy(() => import('pages/concessions'))),
  },
  {
    path: '/tickets',
    Component: withSuspense(lazy(() => import('pages/tickets'))),
  },
  {
    path: '/tickets/:id',
    Component: withSuspense(lazy(() => import('pages/ticket'))),
  },
];

export default router;
