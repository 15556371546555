import { forwardRef, memo, useState } from 'react';
import clsx from 'clsx';

import { useTranslator } from 'utils/translator';
import Timer from 'components/timer';
import Portal from 'components/portal';

import './styles.scss';

const Popup = forwardRef(
  (
    {
      icon,
      title,
      isOpen,
      children,
      overHeader,
      contentSize,
      closeAfterTimer,
      withCloseButton,
      handleClosePopup,
      overSelectedItem,
    },
    ref,
  ) => {
    const { t } = useTranslator();

    const [isCloseEnabled, setIsCloseEnabled] = useState(false);

    const handleClose = () => {
      setIsCloseEnabled(false);

      handleClosePopup();
    };

    return (
      <Portal isOpen={isOpen}>
        <div
          ref={ref}
          className={clsx('popup-wrapper', { 'over-header': overHeader, over: overSelectedItem })}
        >
          <div
            className={clsx('popup-container', { over: overSelectedItem, content: contentSize })}
          >
            {(title || withCloseButton) && (
              <div
                className={clsx('header', {
                  absolute: !title && !icon,
                  disabled: closeAfterTimer && !isCloseEnabled,
                })}
              >
                <h4>
                  {icon}
                  {t(title)}
                </h4>
                {withCloseButton && (
                  <div className="close">
                    {closeAfterTimer && !isCloseEnabled && (
                      <Timer hideMinutes deadline={1} onFinish={() => setIsCloseEnabled(true)} />
                    )}
                    <i className="icon-cross" onClick={handleClose} />
                  </div>
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </Portal>
    );
  },
);

export default memo(Popup);
