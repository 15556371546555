const getBannerData = (pathname, banners, position) => {
  switch (pathname) {
    case '/':
      return banners.filter(
        ({ Position, AvailablePages }) => AvailablePages.includes('Home') && position === Position,
      );
    case '/tickets':
      return banners.filter(({ AvailablePages }) => AvailablePages.includes('OngoingMovies'));
    case '/concessions':
      return banners.filter(({ AvailablePages }) => AvailablePages.includes('Concessions'));
    case '/giftCard':
      return banners.filter(({ AvailablePages }) => AvailablePages.includes('GiftCard'));
    default:
      return [];
  }
};

export default getBannerData;
