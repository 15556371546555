import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  HOME_BANNERS,
  SESSIONS_CLOSE_SESSION,
  SESSIONS_EXPIRE_SESSION,
  SESSIONS_UPDATE_EXPIRE_DATE,
} from 'constants/apiPrefix';

import { saveState, loadState, dayjs, clearState, eraseCookie } from 'utils';
import API from 'utils/api';
import { getTimestamp } from 'utils/timeConverter';

const initialState = {
  banners: [],
  sessionStartTimeIsLoaded: false,
  lang: loadState('lang') || 'en',
  sessionStartTime: loadState('sessionStartTime') || null,
};

export const setSessionStartTime = createAsyncThunk(
  'base/sessionStartTime',
  async (payload, { dispatch }) => {
    const timestamp = getTimestamp(payload);

    saveState('sessionStartTime', timestamp);
    dispatch(setTimestamp(payload));

    const { data } = await API.post(SESSIONS_UPDATE_EXPIRE_DATE, {
      data: {
        ExpireDate: dayjs(new Date()).add(25, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
      },
    });

    if (data === 'Successfully updated the expire date. ') {
      return true;
    } else {
      return false;
    }
  },
);

export const setExpireSession = createAsyncThunk('base/expireSession', async (_, { getState }) => {
  clearState('sessionStartTime');

  const { sessionStartTime } = getState().base;

  const isExpireTime = sessionStartTime - new Date().getTime() < 1;

  const { data } = await API.post(isExpireTime ? SESSIONS_EXPIRE_SESSION : SESSIONS_CLOSE_SESSION);

  eraseCookie('Authorization');
  eraseCookie('TerminalToken');

  if (data === 'Successfully updated the expire date. ') {
    return true;
  } else {
    return false;
  }
});
export const setBanners = createAsyncThunk('base/banners', async () => {
  const {
    data: { Data },
  } = await API.get(HOME_BANNERS);

  return Data;
});

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      saveState('lang', action.payload);
      state.lang = action.payload;
    },
    setTimestamp: (state, action) => {
      const timestamp = getTimestamp(action.payload);

      saveState('sessionStartTime', timestamp);
      state.sessionStartTime = timestamp;
    },
  },
  extraReducers: builder => {
    builder.addCase(setSessionStartTime.fulfilled, (state, action) => {
      state.sessionStartTimeIsLoaded = action.payload;
    });
    builder.addCase(setExpireSession.fulfilled, (state, action) => {
      state.sessionStartTimeIsLoaded = action.payload;
    });
    builder.addCase(setExpireSession.rejected, (state, action) => {
      eraseCookie('Authorization');
      eraseCookie('TerminalToken');
      state.sessionStartTimeIsLoaded = false;
    });
    builder.addCase(setBanners.fulfilled, (state, action) => {
      state.banners = action.payload;
    });
  },
});

export const { setTimestamp, setCurrentLanguage } = baseSlice.actions;

export default baseSlice.reducer;
