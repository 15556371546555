import { dayjs } from 'utils';

export const timeConvert = (min, t) => {
  const hours = min / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);
  return `${rHours}${t('_H_')} ${rMinutes}${t('_M_')}`;
};

export const getTimestamp = minutes => {
  const currentTimestamp = new Date().getTime();
  const fifteenMinutesInMilliseconds = minutes * 60 * 1000;
  return minutes ? currentTimestamp + fifteenMinutesInMilliseconds : currentTimestamp;
};

export const getDateByFormat = (date, format = 'ddd D') => dayjs(date, 'DD-MM-YYYY').format(format);
