const userAgent = navigator.userAgent || navigator.vendor || window.opera;

const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

const isAndroid = /android/i.test(userAgent);

const isWindowsPhone = /windows phone/i.test(userAgent);

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

export { isIOS, isMobile, isAndroid, isWindowsPhone };
