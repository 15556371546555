import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMount } from 'utils/hooks';
import { setBanners } from 'store/reducers/base';
import Time from 'components/time';
import Image from 'components/image';
import LanguageSelect from 'components/languageSelect';

import logo from 'assets/images/logo.svg';

import './styles.scss';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useMount(() => {
    dispatch(setBanners());
  });

  return (
    <header className={clsx({ isHome: pathname === '/' })}>
      <Time />
      <Image src={logo} alt="kinopark" onClick={() => navigate('/')} />
      <LanguageSelect />
    </header>
  );
};

export default Header;
