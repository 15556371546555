const updateItems = (concessions, item, idx) => {
  if (item.Quantity === 0) {
    return [...concessions.slice(0, idx), ...concessions.slice(idx + 1)];
  }

  if (idx === -1) {
    return [...concessions, item];
  }

  return [...concessions.slice(0, idx), item, ...concessions.slice(idx + 1)];
};

const updateOrder = (concessions, orderConcession, id, quantity, IsDealItem) => {
  const concession = concessions.find(({ ItemId }) => ItemId === id);

  const itemIndex = orderConcession.findIndex(({ ItemId }) => ItemId === id);
  const item = orderConcession[itemIndex];

  const { Quantity = 0, total = 0 } = item || {};

  const newItem = {
    ...concession,
    Quantity: Quantity + quantity,
    total: total + quantity * (concession?.Price || 0),
    IsDealItem: concession.IsDealItem || IsDealItem,
  };

  return updateItems(orderConcession, newItem, itemIndex);
};

export default updateOrder;
