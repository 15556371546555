import { useTranslator } from 'utils/translator';
import clsx from 'clsx';

const Button = ({ text, isLoading, disabled, className, icon, onClick }) => {
  const { t } = useTranslator();

  return (
    <button
      onClick={onClick}
      className={clsx('button', className, { disabled, loading: isLoading })}
    >
      {isLoading && <i className="icon-loader" />}
      <p>
        {text && t(text)} {icon && <i className={`icon-${icon}`} />}
      </p>
    </button>
  );
};

export default Button;
