import { useEffect, useState, useRef, useCallback } from 'react';

import API from 'utils/api';
import {
  GET_IDRAM_PAYMENT_RESULT,
  GET_V_POS_PAYMENT_RESULT,
  GET_TELCELL_PAYMENT_RESULT,
} from 'constants/apiPrefix';
import { IDRAM, TELCELL, V_POS } from 'constants/main';

import usePrevious from './usePrevious';

const paymentResultMethods = {
  [IDRAM]: GET_IDRAM_PAYMENT_RESULT,
  [V_POS]: GET_V_POS_PAYMENT_RESULT,
  [TELCELL]: GET_TELCELL_PAYMENT_RESULT,
};

function usePaymentResult(data, type) {
  const timerIdRef = useRef();
  const prevType = usePrevious(type);
  const [transactionResult, setTransactionResult] = useState(null);

  useEffect(() => {
    if (data) {
      const getIdramPaymentResult = async () => {
        try {
          const { data } = await API.post(paymentResultMethods[type]);

          if (data?.Data.State !== 'NotAccepted' && !data?.HasError) {
            setTransactionResult(data);
            return true;
          } else {
            return false;
          }
        } catch (e) {
          setTransactionResult(false);
          return true;
        }
      };

      timerIdRef.current = setTimeout(async function delay() {
        const result = await getIdramPaymentResult();

        if (result) {
          clearTimeout(timerIdRef.current);
        } else {
          timerIdRef.current = setTimeout(delay, 5000);
        }
      }, 5000);

      return () => clearTimeout(timerIdRef.current);
    }
  }, [data, type]);

  const cancel = useCallback(() => {
    clearTimeout(timerIdRef.current);
    setTransactionResult(null);
  }, []);

  useEffect(() => {
    if (prevType !== type && timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
  }, [prevType, type]);

  return { transactionResult, cancel };
}

export default usePaymentResult;
