import { memo } from 'react';

import img1 from 'assets/images/no_movies_img_1.png';
import img2 from 'assets/images/no_movies_img_2.png';

const largeImageRegex = /\/2$/;

const Image = ({ src, alt, onClick, className, missingImage, ...rest }) => (
  <img
    src={src}
    loading="lazy"
    onClick={onClick}
    className={className}
    alt={alt || 'Kinopark Movies'}
    onError={({ target }) => {
      target.onerror = null;
      target.src = missingImage || (largeImageRegex.test(src) ? img2 : img1);
    }}
    {...rest}
  />
);

export default memo(Image);
