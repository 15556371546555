// Home
export const MOVIES = 'Home/Ongoing';
export const HOME_BANNERS = 'Home/Banners';
export const HOME_CONCESSION_ITEMS = 'Home/ConcessionItems';

// Events
export const EVENTS = 'Event/GetEvents';
export const TOP_EVENTS = 'Event/GetTopEvent';
export const EVENTS_BY_ID = 'Event/GetEventById';
export const EVENTS_CATEGORIES = 'Event/GetEventCategories';
export const EVENTS_CATEGORY_ID = 'Event/GetEventByCategoryId';

//Images
export const QR_CODE = 'Images/QrCode';
export const MOVIES_IMAGES = 'Images/Movies';
export const IMAGES_BY_ID = 'Images/GetImageById';
export const QR_CODE_PAYMENT = 'Images/QrCodePayment';
export const CONCESSION_ITEMS_IMAGES = 'Images/ConcessionItem';
export const UPDATE_ACCOUNT_IMAGE = 'Images/UploadAccountImage';
export const ORIGINAL_IMAGES_BY_ID = 'Images/GetOriginalImageById';
export const KINO_PARK_TOP_EVENTS_IMAGES = 'Images/KinoParkEventTop';

// KinoPark

export const MOVIE_TICKET = 'KinoPark/GetTickets';
export const ADD_TICKETS = 'KinoPark/AddTickets';
export const CANCEL_ORDER = 'KinoPark/CancelOrder';
export const CINEMA_HALL = 'KinoPark/GetCinemaHall';
export const SHOWED_MOVIES = 'KinoPark/ShowedMovies';
export const ORDER_DETAILS = 'KinoPark/GetOrderDetails';
export const GET_SINGLE_DEAL_ITEMS = 'KinoPark/GetDealItems';
export const CONCESSION_ITEMS = 'KinoPark/GetConcessionItems';
export const ADD_CONCESSION_ITEMS = 'KinoPark/AddConcessionItems';
export const CONCESSION_ITEMS_NEW = 'KinoPark/GetConcessionItems_New';
export const BANNERS_CONFIGURATION = 'KinoPark/GetBannersConfigurations';
export const SUBSCRIBE_TO_UPCOMING_MOVIES = 'KinoPark/SubscribeToUpcomingMovie';
export const ADD_CONCESSION_ITEMS_SEPARATE = 'KinoPark/AddConcessionItemsSeparate';
export const UNSUBSCRIBE_TO_UPCOMING_MOVIES = 'KinoPark/UnSubscribeToUpcomingMovie';
export const USER_UPCOMING_MOVIES_SUBSCRIPTION = 'KinoPark/UserUpcomingMovieSubscribe';

// Google
export const GOOGLE_AUTH_LOGIN = 'GoogleAuthentication/Login';
export const GOOGLE_AUTH_URL = 'GoogleAuthentication/GetAuthenticationUrl';

// User
export const LOGIN = 'User/Login';
export const LOGOUT = 'User/Logout';
export const REGISTER = 'User/Register';
export const REMOVE_CARD = 'User/RemoveCard';
export const CHANGE_PASSWORD = 'User/ChangePassword';
export const FORGOT_PASSWORD = 'User/ForgotPassword';
export const UPDATE_USER_EMAIL = 'User/UpdateUserEmail';
export const ACCOUNT_DETAILS = 'User/GetAccountDetails';
export const VERIFICATION_CODE = 'User/GetVerificationCode';
export const CHECK_VERIFICATION_CODE = 'User/CheckVerificationCode';
export const UPDATE_ACCOUNT_INFORMATION = 'User/UpdateAccountInformation';
export const VERIFICATION_CODE_FOR_FORGOT_PASSWORD = 'User/GetVerificationCodeForForgotPassword';

// Payment
export const REFUND = 'Payment/Refund';
export const SMS_VERIFICATION = 'Verification/SMS';
export const EMAIL_VERIFICATION = 'Verification/Email';
export const GET_GIFT_CARDS = 'Home/KinoParkGiftCards';
export const VERIFICATION_VERIFY = 'Verification/Verify';
export const TRANSACTION_HISTORY = 'Payment/GetTransactionsHistory';
export const TRANSACTION_DETAILS = 'Payment/GetTransactionDetails';
export const USER_CARDS_WITH_EMAIL = 'Payment/GetUserCardsWithEmail';
export const GET_AVAILABLE_GIFT_CARD = 'KinoPark/GetAvailableGiftCards';
export const GET_USER_GIFT_CARD = 'Payment/GetUserKinoparkGiftCards';
export const GET_V_POS_PAYMENT_RESULT = 'Payment/GetVPosPaymentResult';
export const GET_IDRAM_PAYMENT_RESULT = 'Payment/GetIdramPaymentResult';
export const GET_TELCELL_PAYMENT_RESULT = 'Payment/GetTelCellPaymentResult';
export const COMPLETE_ORDER_BY_V_POS = 'Payment/CompleteKinoParkOrderByVPos';
export const COMPLETE_ORDER_BY_IDRAM = 'Payment/CompleteKinoParkOrderByIdram';
export const COMPLETE_ORDER_BY_TELCELL = 'Payment/CompleteKinoParkOrderByTelCell';

// Subscriptions
export const SUBSCRIBE = 'Home/Subscribe';
export const SEND_MESSAGE = 'Home/SendMessage';

// Promo
export const SUGGESTED_DEALS = 'KinoPark/SuggestedDeals';
export const APPLY_PROMO_CODE = 'kinoPark/ApplyPromoCode';
export const REMOVE_PROMO_CODE = 'KinoPark/RemovePromoCode';
export const KINO_PARK_PROMO_TOP = 'Images/KinoParkPromoTop';
export const KINO_PARK_PROMO_BODY = 'Images/KinoParkPromoBody';
export const PROMO_WEB_CONTENT = 'KinoPark/getpromowebcontent';
export const PROMO_CODE_APPLICATION = 'kinopark/promocodeapplication';
export const PROMO_CODE_IMAGES_POSTER = 'Images/KinoParkPromocodeApplicationPoster';

// Gift Cards
export const GIFT_CARDS = 'KinoPark/GetKinoParkGiftCards';
export const GIFT_CARDS_IMAGES = 'Images/KinoParkGiftCard';
export const GIFT_CARDS_IMAGES_POSTER = 'Images/KinoParkGiftCardPoster';
export const GIFT_CARDS_LOTTIE_IMAGES = 'Images/KinoParkGiftCardLottie';
export const BUY_GIFT_CARD_BY_IDRAM = 'payment/BuyGiftCardByIdram';
export const BUY_GIFT_CARD_BY_BINDING = 'payment/BuyGiftCardByBinding';
export const BUY_GIFT_CARD_BY_VPOS = 'payment/BuyGiftCardByVPos';
export const BUY_GIFT_CARD_BY_TELCELL = 'payment/BuyGiftCardByTelCell';

// Sessions

export const SESSIONS_CLOSE_SESSION = 'Session/CloseSession';
export const SESSIONS_EXPIRE_SESSION = 'Session/ExpireSession';
export const SESSIONS_UPDATE_EXPIRE_DATE = 'Session/UpdateExpireDate';

// Staging

export const CREATE_KINO_PARK_ORDER = 'Staging/CreateKinoParkOrderStaging';
export const GET_KINO_PARK_ORDER = 'Staging/GetKinoParkOrderStagingByToken';
export const GET_KINO_PARK_GIFT_CARD_ORDER = 'Staging/CreateGiftCardOrderStaging';
export const CREATE_KINO_PARK_GIFT_CARD_ORDER = 'Staging/CreateGiftCardOrderStaging';
// export const GET_KINO_PARK_ORDER = 'Staging/GetKinoParkOrderStagingById';
