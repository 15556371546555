import { createSlice } from '@reduxjs/toolkit';

import API from 'utils/api';
import {
  ORDER_DETAILS,
  CONCESSION_ITEMS,
  ADD_CONCESSION_ITEMS,
  HOME_CONCESSION_ITEMS,
} from 'constants/apiPrefix';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCookie } from 'utils';

export const getConcessionsData = createAsyncThunk('concession/concession', async MovieId => {
  if (MovieId) {
    const {
      data: { Data },
    } = await API.post(CONCESSION_ITEMS, {
      data: { MovieId: MovieId },
    });

    return Data;
  }

  const {
    data: { Data },
  } = await API.get(HOME_CONCESSION_ITEMS);

  setCookie('Authorization', Data.Authorization, 3);
  setCookie('TerminalToken', Data.TerminalToken, 3);

  return Data.Data;
});

export const addConcessionItems = createAsyncThunk(
  'concession/addConcession',
  async (payload, { dispatch }) => {
    dispatch(setConcessionsLoading(true));
    try {
      const response = await API.post(ADD_CONCESSION_ITEMS, {
        data: {
          ConcessionItems: payload,
        },
      });

      if (!response.data.HasError) {
        const {
          data: { Data },
        } = await API.post(ORDER_DETAILS);

        return Data;
      }
    } catch (e) {
    } finally {
      dispatch(setConcessionsLoading(false));
    }
  },
);

const initialState = {
  concessions: [],
  activeFilter: 0,
  orderedConcession: {
    data: null,
    loading: false,
  },
};

export const concessionSlice = createSlice({
  name: 'concession',
  initialState,
  reducers: {
    setConcessionsActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setConcessionsLoading: (state, action) => {
      state.orderedConcession.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getConcessionsData.fulfilled, (state, action) => {
      state.concessions = action.payload;
    });
    // builder.addCase(addConcessionItems.fulfilled, (state, action) => {
    //   state.orderedConcession.data = action.payload;
    // });
  },
});

export const { setConcessionsActiveFilter, setConcessionsLoading } = concessionSlice.actions;

export default concessionSlice.reducer;
