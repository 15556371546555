const findSelectedTicketIndex = (tickets, selectedTicket) =>
  tickets.findIndex(
    ({
      RowIndex: row,
      AreaNumber: area,
      ColumnIndex: column,
      AreaCategoryCode: categoryCode,
      RowPhysicalIndex: rowPhysicalIndex,
      ColumnPhysicalIndex: columnPhysicalIndex,
    }) => {
      const {
        RowIndex,
        AreaNumber,
        ColumnIndex,
        RowPhysicalIndex,
        AreaCategoryCode,
        ColumnPhysicalIndex,
      } = selectedTicket;

      return (
        row === RowIndex &&
        area === AreaNumber &&
        column === ColumnIndex &&
        categoryCode === AreaCategoryCode &&
        rowPhysicalIndex === RowPhysicalIndex &&
        columnPhysicalIndex === ColumnPhysicalIndex
      );
    },
  );

export default findSelectedTicketIndex;
