import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { isEqual } from 'utils';

const useMemoSelector = (selector, comparator = isEqual) => {
  const resultRef = useRef(null);
  const isMemoEqualRef = useRef(true);

  const result = useSelector(selector, (prev, next) => {
    const equality = comparator(prev, next);
    isMemoEqualRef.current = equality;
    return equality;
  });

  if (!isMemoEqualRef.current || !comparator(result, resultRef.current)) {
    resultRef.current = result;
    isMemoEqualRef.current = true;
  }

  return resultRef.current;
};

export default useMemoSelector;
