import base from './base';
import tickets from './tickets';
import concessions from './concessions';
import giftCard from './giftCard';

const reducers = {
  base,
  tickets,
  giftCard,
  concessions,
};

export default reducers;
