const isDisabledSeat = (RowPhysicalIndex, ColumnPhysicalIndex, ScreenName) => {
  switch (ScreenName) {
    case 'Screen 2':
    case 'Screen 4':
      return RowPhysicalIndex === 1 && (ColumnPhysicalIndex === '7' || ColumnPhysicalIndex === '8');
    case 'Screen 3':
    case 'Screen 5':
      return RowPhysicalIndex === 1 && (ColumnPhysicalIndex === '6' || ColumnPhysicalIndex === '7');
    case 'Screen 6':
      return RowPhysicalIndex === 1 && (ColumnPhysicalIndex === '6' || ColumnPhysicalIndex === '7');
    default:
      return;
  }
};

export default isDisabledSeat;
