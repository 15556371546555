import { useState } from 'react';

import { dayjs } from 'utils';
import { useMount } from 'utils/hooks';

import './styles.scss';

const Time = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useMount(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className="time-block" onDoubleClick={() => window.location.reload()}>
      {dayjs(currentTime).format('HH:mm')}
    </div>
  );
};

export default Time;
