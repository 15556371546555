import { useState } from 'react';

import { useMount } from 'utils/hooks';
import { useTranslator } from 'utils/translator';
import Popup from 'components/popup';
import Button from 'components/button';

const GlobalError = () => {
  const { t } = useTranslator();

  const [hasError, setHasError] = useState(false);

  useMount(() => {
    window.addEventListener(
      'hasError',
      e => {
        setHasError(true);
      },
      false,
    );
  });

  return (
    <Popup isOpen={hasError} overHeader contentSize>
      <div className="error-popup">
        <i className="icon-bomb" />
        <p>{t('_SomethingWentWrong_')}</p>
        <Button
          text="_StartAgain_"
          className="outline primary small"
          onClick={() => (window.location.pathname = '/')}
        />
      </div>
    </Popup>
  );
};

export default GlobalError;
