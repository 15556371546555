import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { FAILED, LOADED, LOADING } from 'constants/data';
import { setCookie } from 'utils';
import API from 'utils/api';

export const getData = createAsyncThunk(
  'tickets/tickets',
  async ({ prefix, body, payload }, { dispatch, getState }) => {
    dispatch(setLoading(LOADING));

    const {
      data: {
        Data: { OngoingMovies, Sessions, Authorization, TerminalToken },

        HasError: hasError,
        Message: message,
        ResponseMessage: responseMessage,
      },
    } = await API.get(prefix, {
      data: body,
      hasAuth: false,
    });

    setCookie('Authorization', Authorization, 3);
    setCookie('TerminalToken', TerminalToken, 3);

    dispatch(setLoading(LOADED));

    return {
      message,
      hasError,
      responseMessage,
      sessions: Sessions.reduce((acc, { ShowDate, Details }) => {
        acc[ShowDate] = Details;
        return acc;
      }, {}),
      OngoingMovies,
      ...payload,
    };
  },
);

const initialState = {
  loading: LOADING,
  tickets: {},
  sessions: {},
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.tickets = action.payload;
      state.sessions = action.payload.sessions;
    });
    builder.addCase(getData.rejected, state => {
      state.loading = FAILED;
    });
  },
});

export const { setLoading, getSessions } = ticketSlice.actions;

export default ticketSlice.reducer;
