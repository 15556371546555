import { memo, useEffect, useMemo, useState } from 'react';

import useTimer from 'utils/hooks/useTime';
import { getTimestamp } from 'utils/timeConverter';

const Timer = ({ deadline, withoutConvert, hideMinutes, onFinish }) => {
  const endTime = useMemo(
    () => (withoutConvert ? deadline : getTimestamp(deadline)),
    [withoutConvert, deadline],
  );

  const { minutes, seconds } = useTimer(endTime);

  const [isFinished, setIsFinished] = useState(false);

  const zeroPad = num => `${Math.floor(num)}`.padStart(2, '0');

  useEffect(() => {
    setIsFinished(false);
  }, [endTime]);

  useEffect(() => {
    if (!isFinished && minutes + seconds <= 0) {
      onFinish?.();

      setIsFinished(true);
    }
  }, [isFinished, minutes, onFinish, seconds]);

  if (isFinished) {
    return <span className="timer">{hideMinutes ? '00' : '00:00'}</span>;
  }

  return (
    <span className="timer">
      {!hideMinutes && `${zeroPad(minutes)}:`}
      {zeroPad(seconds)}
    </span>
  );
};

export default memo(Timer);
