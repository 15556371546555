import { uuid } from 'utils';
import {
  QR_CODE,
  IMAGES_BY_ID,
  MOVIES_IMAGES,
  QR_CODE_PAYMENT,
  GIFT_CARDS_IMAGES,
  KINO_PARK_PROMO_TOP,
  ORIGINAL_IMAGES_BY_ID,
  KINO_PARK_PROMO_BODY,
  CONCESSION_ITEMS_IMAGES,
  GIFT_CARDS_IMAGES_POSTER,
  PROMO_CODE_IMAGES_POSTER,
  KINO_PARK_TOP_EVENTS_IMAGES,
} from 'constants/apiPrefix';
import visa from 'assets/images/visa-logo.png';
import iDram from 'assets/images/logo_idram.png';
import mastercard from 'assets/images/mastercard-logo.png';

export const cardTypes = {
  1: visa,
  2: mastercard,
  5: iDram,
};

const { REACT_APP_BASE_URL } = process.env;

export const baseURL = REACT_APP_BASE_URL;
export const youTubeEmbedURL = 'https://www.youtube.com/embed/';

export const navigation = [
  {
    id: uuid(),
    to: '/tickets',
    image: 'tickets',
    label: '_Tickets_',
  },
  {
    id: uuid(),
    image: 'concessions',
    label: '_FoodAndDrinks_',
    to: '/concessions?start=home',
  },
  // {
  //   id: uuid(),
  //   image: 'gift',
  //   to: '/giftCard',
  //   label: '_GiftCard_',
  // },
];

export const languages = [
  {
    id: 'en',
    title: 'Eng',
  },
  {
    id: 'am',
    title: 'Հայ',
  },
  // {
  //   id: 'ru',
  //   title: 'Рус',
  // },
];

export const bannerData = {
  banner1: {
    ownerId: 264110,
    containerId: uuid(),
    params: {
      p1: 'cqdyp',
      p2: 'fzpa',
      puid22: '',
      puid27: '',
      puid33: '',
      puid51: '',
    },
  },
  banner2: {
    ownerId: 264110,
    containerId: uuid(),
    params: {
      p1: 'cqdyp',
      p2: 'fzpa',
      puid22: '',
      puid27: '',
      puid33: '',
      puid51: '',
    },
  },
  banner3: {
    ownerId: 264110,
    containerId: uuid(),
    params: {
      p1: 'cqhrf',
      p2: 'gyun',
      puid22: '',
      puid27: '',
      puid33: '',
      puid51: '',
    },
  },
};

export const prices = [
  { value: 5000, text: '5000' },
  { value: 10000, text: '10.000' },
  { value: 15000, text: '15.000' },
  { value: 20000, text: '20.000' },
];

export const getQRCodeImage = OrderId => `${baseURL}${QR_CODE}/${OrderId}`;
export const getImageUrlById = MovieId => `${baseURL}${IMAGES_BY_ID}/${MovieId}`;
export const getMovieImageUrl = MovieId => `${baseURL}${MOVIES_IMAGES}/${MovieId}`;
export const getPaymentQRCodeImage = OrderId => `${baseURL}${QR_CODE_PAYMENT}/${OrderId}`;
export const getConcessionImageUrl = ItemId => `${baseURL}${CONCESSION_ITEMS_IMAGES}/${ItemId}`;
export const getEventImageUrl = MovieId => `${baseURL}${KINO_PARK_TOP_EVENTS_IMAGES}/${MovieId}`;
export const getOriginalImageUrlById = MovieId => `${baseURL}${ORIGINAL_IMAGES_BY_ID}/${MovieId}`;

export const getPromoCodePoster = () => `${baseURL}${PROMO_CODE_IMAGES_POSTER}`;
export const getPromoTopImage = PromoId => `${baseURL}${KINO_PARK_PROMO_TOP}/${PromoId}`;
export const getPromoBodyImage = PromoId => `${baseURL}${KINO_PARK_PROMO_BODY}/${PromoId}`;
export const getGiftCardVideo = PromoId => `${baseURL}${GIFT_CARDS_IMAGES}/${PromoId}`;
export const getGiftCardImage = PromoId => `${baseURL}${GIFT_CARDS_IMAGES_POSTER}/${PromoId}`;
