import { createSlice } from '@reduxjs/toolkit';

import API from 'utils/api';
import { setCookie } from 'utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_GIFT_CARDS } from 'constants/apiPrefix';

export const getGiftCardData = createAsyncThunk('giftCards/giftCards', async () => {
  const {
    data: { Data },
  } = await API.get(GET_GIFT_CARDS);

  setCookie('Authorization', Data.Authorization, 3);
  setCookie('TerminalToken', Data.TerminalToken, 3);

  return Data.Data;
});

const initialState = {
  giftCards: [],
};

export const giftCardSlice = createSlice({
  name: 'giftCards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getGiftCardData.fulfilled, (state, action) => {
      state.giftCards = action.payload;
    });
  },
});

export default giftCardSlice.reducer;
