import { Suspense } from 'react';

import Loader from 'components/loader';

const withSuspense = Component => props => (
  <Suspense>
    <Component {...props} />
  </Suspense>
);

export default withSuspense;
