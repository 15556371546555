import { memo } from 'react';
import ReactDOM from 'react-dom';

import { useLockBodyScroll } from 'utils/hooks';

const Portal = ({ isOpen = false, children }) => {
  useLockBodyScroll();
  return isOpen && ReactDOM.createPortal(children, document.body);
};

export default memo(Portal);
