const localStorage = 'localStorage';

const loadState = (key, storage = localStorage) => {
  try {
    const serializedState = window[storage].getItem(key);
    if (serializedState === null) {
      return;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(err);
  }
};

const saveState = (key, state, storage = localStorage) => {
  try {
    const serializedState = JSON.stringify(state);
    window[storage].setItem(key, serializedState);
  } catch (err) {
    console.error(err);
  }
};

const clearState = (key, storage = localStorage) => {
  window[storage].removeItem(key);
};

export { loadState, saveState, clearState };
