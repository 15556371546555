import { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

import { getBaseLang } from 'store/selectors';
import { useMemoSelector } from 'utils/hooks';

const TranslatorContext = createContext();

const TranslatorProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const currentLanguage = useMemoSelector(getBaseLang);

  const changeLanguage = useCallback(async lang => {
    const response = await fetch(`/translations/${lang}.json`);
    const result = await response.json();

    setTranslations({ [lang]: result || {} });
  }, []);

  useEffect(() => {
    changeLanguage(currentLanguage);
  }, [changeLanguage, currentLanguage]);

  const translate = useCallback(
    (str, ...args) => {
      const currentTranslations = translations[currentLanguage] || Object.values(translations)[0];
      const translation = currentTranslations?.[str] || str;

      return !args.length ? translation : translation.replace(/__param__/g, () => args.shift());
    },
    [translations, currentLanguage],
  );

  const value = useMemo(() => ({ translate }), [translate]);

  return <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>;
};

const useTranslator = () => {
  const { translate: t } = useContext(TranslatorContext) || {};

  return { t };
};

export { useTranslator, TranslatorProvider };
