import API from 'utils/api';
import { CANCEL_ORDER, ADD_TICKETS } from 'constants/apiPrefix';

const log = (description, bgColor, color = 'white') =>
  console.log(
    `%c ${description} `,
    `background-color: ${bgColor}; color: ${color}; margin: 0 10px;`,
  );

const cancelOrder = async (SessionId, selectedTickets, section) => {
  const { data: { HasError, Data } = {} } = await API.post(CANCEL_ORDER, {
    data: {
      SessionId,
      SelectedSeats: selectedTickets,
    },
  });

  if (HasError) {
    log(`Do Something by => ${section}`, 'red');
  } else {
    log(`Order canceled by => ${section}`, 'green');
  }

  return { HasError, Data };
};

const addTicket = async (selectedSession, selectedTickets) => {
  const {
    data: { HasError, Data, ResponseMessage },
  } = await API.post(ADD_TICKETS, {
    data: {
      SessionId: selectedSession.SessionId,
      SelectedSeats: selectedTickets,
    },
  });

  if (HasError) {
    log('Do Something ', 'red');
  } else {
    log('Ticket is added ', 'green');
  }

  return { HasError, Data, ResponseMessage };
};

export { cancelOrder, addTicket };
