const getCurrentSession = (sessions, selectedSessionId) => {
  const flattedSessions = Object.values(sessions || {}).flat();

  for (let i = 0; i < flattedSessions.length; i++) {
    const selectedSession = flattedSessions[i].Sessions.Details?.find(
      ({ SessionId }) => SessionId === selectedSessionId,
    );
    if (selectedSession) {
      return {
        selectedSession: {
          ...selectedSession,
          ShowDate: flattedSessions[i].Sessions.ShowDate,
        },
        ShowDate: flattedSessions[i].Sessions.ShowDate,
      };
    }
  }

  return {};
};

export default getCurrentSession;
