import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { store } from 'store';
import { TranslatorProvider } from 'utils/translator';
import Header from 'sections/header';
import GlobalError from 'components/globalError';

import router from 'router';

import 'assets/scss/style.scss';

function App() {
  return (
    <Provider store={store}>
      <TranslatorProvider>
        <BrowserRouter>
          <Header />
          <GlobalError />
          <main>
            <Routes>
              {router.map(({ Component, path }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Routes>
          </main>
        </BrowserRouter>
      </TranslatorProvider>
    </Provider>
  );
}

export default App;
