import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.Ls.en.weekStart = 1;

export default dayjs;
