import { useState, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { languages } from 'config';
import { useMemoSelector, useOnClickOutside } from 'utils/hooks';
import { getBaseLang } from 'store/selectors';
import { setCurrentLanguage } from 'store/reducers/base';

import './styles.scss';

const LanguageSelect = ({ isLine }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const lang = useMemoSelector(getBaseLang);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  const selectedLanguage = languages.find(({ id }) => id === lang);
  const otherItems = languages.filter(({ id }) => id !== lang);

  const handleLanguageChange = lang => {
    dispatch(setCurrentLanguage(lang));

    if (!isLine) {
      setIsOpen(false);
    }
  };

  if (isLine) {
    return (
      <div className="language-line-mode">
        {languages.map(({ title, id }) => (
          <div
            key={id}
            onClick={() => handleLanguageChange(id)}
            className={clsx({ selected: id === lang })}
          >
            {title}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div ref={ref} className="language-select-wrapper">
      <div onClick={() => setIsOpen(prev => !prev)} className={clsx('item', { selected: isOpen })}>
        {selectedLanguage.title}
      </div>
      <div className={clsx('other-items', { open: isOpen })}>
        {otherItems.map(({ title, id }) => (
          <div
            key={id}
            className="item"
            onClick={() => {
              setIsOpen(false);
              dispatch(setCurrentLanguage(id));
            }}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
